
body {
	font-family: 'Battambang', Arial, Helvetica, sans-serif;
	background: #F6F6F6;
}
.h4, h4 {
	line-height: 40px;
}
.clear {
	clear: both;
}
#top {
	/*position: relative;*/
	/*height: 120px;*/
	background-color: #161616;
	padding-bottom: 15px;
}
#top .logo {
	/*background-color: #9E0910;*/
	font-size: 24px;
	font-weight: bold;
	float: left;
	/*padding-right: 18px;*/
	margin-top: 18px;
	margin-bottom: 5px;
}
#top .logo a{
	color: #ffffff;
}
#top .logo a:hover{
	text-decoration: none;
}
#top .ads {
	float: right;
	margin-top: 15px;
	max-width: calc(100% - 100px);
}

@media (max-width:768px) {
	#top .logo {
		margin-top: 13px;
	}
}

.item > a img,
#top .ads img{
	display: block;
    height: auto;
    max-width: 100%;
    color: #cccccc;
    font-style: italic;
}
#top .logo img {
	height: 82px;
}
.container{
	position: relative;
}
#banner {
	margin-bottom: 20px;
}
#banner_mobile {
	display: none;
}
#top_right_ads {
	width: 300px;
	float: right;
}
.navbar-toggle {
    border: 1px solid transparent !important;
}
.navbar-brand {
    padding-top: 5px;
    display: none;
}
.navbar-brand img {
    height: 38px;
}
.navbar-toggle:focus,
.navbar-toggle:hover{
    background-color: transparent !important;
}
.navbar-toggle:hover{
    border: 1px solid transparent !important;
}
.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #ffffff;
}
#features_post {
	width:calc(100% - 308px);
	float: left;
	padding: 0 15px;
}
.ads_items {
	margin: 0 auto;
	text-align: center;

	img {
		margin: 0 auto;
		max-width: 100%;
		height: auto;
		display: block;
	}
	iframe {
		margin: 0 auto;
	}
}
#top_right_ads .ads_items {
	height: 250px;
	background-color: #dddddd;
}
.ads_items.ad-250 {
	width: 300px;
	height: 250px;
	background-image: url('../../img/logo.png');
	background-color: #cccccc;
	background-position: center center;
	background-size: auto 30%;
	background-repeat: no-repeat;
	margin: 15px auto;
}
#top_right_ads .ads_items:first-child {
	margin-bottom: 8px;
}
#features_post .item {
	position: relative;
	padding: 0;
	height: 254px;
	overflow: hidden;
}
#features_post .item a{
	color: #ffffff;
	font-weight: bold;
	font-size: 16px;
}
#features_post .item.col-sm-7 > a img{
	width: 100%;
}
#features_post .item.col-sm-5 > a img{
	height: 100% !important;
	max-width: none !important;
}
#features_post .item img{
	margin: 0 auto;
}
#banner_mobile .item .caregory ,
#features_post .item .caregory {
	font-size: 13px;
	background: #222222;
	padding: 0 8px 0 15px;
	position: absolute;
	top: 10px;
	left: 0px;
	color: #ffffff;
	height: 30px;
	line-height: 30px;
}
#banner_mobile .item .caregory .corner,
#features_post .item .caregory .corner{
	position: absolute;
	top:0;
	right: -13px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 30px 0 0 13px;
	border-color: transparent transparent transparent #555555;
}
#features_post .item .decription .content {
	font-size: 13px;
	font-weight: normal;
	margin-bottom: 10px;
	display: none;
	color: rgba(255,255,255,0.8);
}
#features_post .item .decription .title {
  text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}
#features_post .item .decription .title,
#banner_mobile .item .decription .title ,
.content_slider .item .decription .title {
	margin: 10px 0;
  font-size: 18px;
  line-height: 30px;
}
span.title {
	font-weight: bold;
}
.content-grp-img {
	margin-bottom: 20px;
}
.content-grp-img .caption {
	font-size: 12px;
	font-style: italic;
	color: #777777;
	text-align: center;
}
.content_slider {
    margin: 0 auto;
    margin-bottom: 15px;
    overflow: hidden;
    height: 450px;
}
.content_slider .carousel-inner{
    padding: 0 !important;
    background: #000000;
    /*overflow: visible;*/
}
.carousel-inner .item {
	width: 100%;
	height: 100%;
}
.carousel-inner .item .img{
	background-size: auto 100%;
	height: 100%;
}
.content_slider .item {
    margin: 0;
}
.content_slider .item img {
    display: block;
    margin: 0 auto;
    height: 360px;
    width: auto;
}
.item .img {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: #161616;
    /*-webkit-transition-duration: .3s;
  	transition-duration: .3s;*/
}
.right-small-post.item .img {
	width: 40px;
	height: 40px;
    background-size: auto 100%;
}
#features_post .item.col-sm-5 .img {
    background-size: auto 100%;
}
.content_slider .carousel-caption .title{
	margin: 0 !important;
	font-size: 14px !important;
}

.content_slider .carousel-caption {
    padding: 10px 15px;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: left;
    background-color: #000000;
    font-size: 13px;
    color: #999999;
    position: relative !important;
    display: block;
    overflow: auto;
}

#features_post .item .mean-decription .decription,
#banner_mobile .item .mean-decription .decription {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
	padding: 30px 20px 20px 20px;
	text-align: left;
}
#banner_mobile .item .mean-decription {
	position: relative;
}
#banner_mobile .item .mean-decription .decription {
	padding: 30px 20px 0px 20px;
	z-index: 1;
}
#features_post .item .bg ,
#features_post .item .mean-decription ,
#banner_mobile .item .bg {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.5+0,0.8+60,1+100 */
background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.8) 60%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.8) 60%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.8) 60%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
opacity: 0.5;
}
#features_post .item .mean-decription {
	background: transparent;
	opacity: 1;
}
#features_post .item .decription .date {
	font-size: 12px;
	font-weight: normal;
}
#features_post .item .decription .date .comments{
	float: right;
}
.carousel-control {
	z-index: 200;
	background: transparent !important;
}
.carousel-caption {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.8+100 */
background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
}
.content_slider .carousel-control {
	background: rgba(0,0,0,0.5) !important;
	color: #ff4545;
	width: 120px;
	height: 120px;
	padding: 0;
	opacity: 1;
	bottom: calc(50% - 50px);
	top: auto;
	-webkit-border-radius: 100px;
	border-radius: 100px;
	text-align: center;
	text-shadow: none;
}
.content_slider .carousel-control:hover {
	background: rgba(0,0,0,1) !important;
	color: #D90C16;
}
.content_slider .carousel-control.left {
	left: -60px;
	text-align: right;
	padding-right: 25px;
}
.content_slider .carousel-control.right {
	right: -60px;
	text-align: left;
	padding-left: 25px;
}
.content_slider .carousel-control .fa {
	margin: 0;
	font-size: 75px;
	line-height: 120px;
}
.home-tabs {
	border-bottom: 3px solid #555555;
}
.home-tabs > li{
	float: right;
}
.home-tabs > li.title{
	float: left;
}
.home-tabs > li.title > a{
	font-size: 18px;
	background: #555555;
	color: #ffffff;
	-webkit-border-radius: 0;
	border-radius: 0;
	height: 46px;
	border: 0;
	position: relative;
  padding: 10px 20px;
}
.home-tabs > li.title.active > a,
.home-tabs > li.title.active > a:focus,
.home-tabs > li.title.active > a:hover {
	color: #ffffff !important;
	font-weight: normal !important;
}
.home-tabs > li.title > a:hover{
	color: #ffffff;
	background: #555555;
}
.home-tabs > li.title > a .corner{
	position: absolute;
	top:0;
	right: -20px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 46px 0 0 20px;
	border-color: transparent transparent transparent #555555;
}
.nav-tabs > li > a {
    color: #888888;
    border: 0;
    padding: 15px 10px 5px 10px;
}
.nav-tabs > li > a:hover {
    background: transparent;
    color: #555555;
}
.mobile {
	display: none;
}
.home-tabs > li.active > a, .home-tabs > li.active > a:focus, .home-tabs > li.active > a:hover {
    background: transparent;
    border: 0;
    font-weight: bold;
    color: #333333;
}

.home-tabs.hot-new {
	border-color: #F2272F;
}
.home-tabs.hot-new > li.title a {
	background: #F2272F;
}
.home-tabs.hot-new > li.title a .corner{
	border-color: transparent transparent transparent #F2272F;
}

.home-tabs > li.title i {
	font-weight: 900;
}

.home-tabs.entertainment {
	border-color: #FA5480;
}
.home-tabs.entertainment > li.title a {
	background: #FA5480;
}
.home-tabs.entertainment > li.title a .corner{
	border-color: transparent transparent transparent #FA5480;
}

.home-tabs.technology {
	border-color: #38C378;
}
.home-tabs.technology > li.title a {
	background: #38C378;
}
.home-tabs.technology > li.title a .corner{
	border-color: transparent transparent transparent #38C378;
}

.home-tabs.science-technology {
	border-color: #38C378;
}
.home-tabs.science-technology > li.title a {
	background: #38C378;
}
.home-tabs.science-technology > li.title a .corner{
	border-color: transparent transparent transparent #38C378;
}

.home-tabs.life {
	border-color: #F9A62B;
}
.home-tabs.life > li.title a {
	background: #F9A62B;
}
.home-tabs.life > li.title a .corner{
	border-color: transparent transparent transparent #F9A62B;
}

.home-tabs.life-social {
	border-color: #F9A62B;
}
.home-tabs.life-social > li.title a {
	background: #F9A62B;
}
.home-tabs.life-social > li.title a .corner{
	border-color: transparent transparent transparent #F9A62B;
}

.home-tabs.sports {
	border-color: #4390F8;
}
.home-tabs.sports > li.title a {
	background: #4390F8;
}
.home-tabs.sports > li.title a .corner{
	border-color: transparent transparent transparent #4390F8;
}

.home-tabs.politics {
	border-color: red;
}
.home-tabs.politics > li.title a {
	background: red;
}
.home-tabs.politics > li.title a .corner{
	border-color: transparent transparent transparent red;
}
#loading_more_post {
	text-align: center;
	padding: 20px 0;
}
.white-bg {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 2px 1px rgba(0,0,0,0.2);
	box-shadow: 1px 2px 2px 1px rgba(0,0,0,0.2);
}
.home-tab-content {
	padding: 20px 35px;
	position: relative;
	min-height: 380px;
	overflow: hidden;

	.tab-content-banner {
		margin: -20px -35px 30px -35px;
	}
}
.hot-news {
	margin: 0;
}
.hot-news .item {
	padding: 0 1px;
}
.hot-news .item a .title{
	padding: 10px 15px 10px 0;
	font-weight: bold;
	font-size: 15px;
}
.hot-news .item a {
	color: #444444;
}
.hot-news .item a:hover {
	color: #222222;
	text-decoration: none;
}
.hot-news-items {
	width:calc(100% - 325px);
	height: 300px;
	float: left;
	padding: 0;
}

@media (max-width:768px) {
	.hot-news-items {
		height: auto;
	}
}

.hot-news-ads {
	width: 300px;
	float: right;
	height: 320px;
}
.hot-news-items .row {
	margin-top: -20px;
}
.hot-news-items .item {
	height: 153px;
	margin-top: 20px;
	overflow: hidden;
}
.hot-news-items .item a .title {
	font-size: 13px;
	line-height: 20px;
	margin-top: 5px;
}
.hot-news-items .item a {
	color: #444444;
}
.hot-news-items .item a:hover {
	color: #222222;
	text-decoration: none;
}
.hot-news-ads > .ads_items:first-child{
	margin-bottom: 8px;
}

.news-items .item {
	margin-top: 15px;
}
.news-items .item a {
	font-size: 14px;
	line-height: 22px;
	margin-top: 8px;
	color: #444444;
}
.news-items .item a:hover {
	color: #222222;
	text-decoration: none;
}
.tab-item {
	margin-top: 30px;
}
.tab-item:first-child {
	margin-top: 0;
}

.tab-by-category .left_news {
	float: left;
	width: 350px;
	position: relative;
	height: 320px;
}
.tab-by-category .left_news a .title{
	padding: 15px 20px;
	font-size: 22px;
	font-weight: bold;
	background: #333333;
	/*height: 140px;*/
}
.tab-by-category .left_news a{
	color: #ffffff;
}
.tab-by-category .left_news a:hover {
	text-decoration: none;
}
.tab-by-category .news-items {
	width:calc(100% - 375px);
	/*height: 300px;*/
	float: right;
	padding: 0;
}
.tab-by-category .news-items .row {
	margin-top: -20px;
	margin-left: -5px;
	margin-right: -5px;
}
.tab-by-category .news-items .item {
	height: 159px;
	margin-top: 20px;
	overflow: hidden;
	padding: 0 5px;
}
.tab-by-category .news-items .item a .title {
	font-size: 13px;
	line-height: 20px;
	margin-top: 5px;
}
.tab-by-category .news-items .item a {
	color: #444444;
}
.tab-by-category .news-items .item a:hover {
	color: #222222;
	text-decoration: none;
}
.tab-by-category .tab-pane {
	position: relative;
	/*min-height: 300px;*/
}

/*Loading*/
.ajax-content {
	min-height: 100px;
}
.loading-content {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.8);
}
.loading-content i{
	position: absolute;
	left: 50%;
	bottom: 40%;
}

.single-left-content {
	width:calc(100% - 325px);
	min-height: 300px;
	float: left;
	padding: 0;
	// padding: 30px 44px;
	// padding-top: 10px;
}
.single-right-content {
	width: 300px;
	float: right;
}
.single-right-content .ads_items {
	margin-top: 8px;
}
.single-right-content .ads_items:first-child {
	margin-top: 0px;
}
.single-left-content .header {
	margin-bottom: 10px;
	padding-bottom: 15px;
	border-bottom: 1px solid #eeeeee;
	margin-top: 10px;
}
.header .title {
	font-size: 24px;
	line-height: 38px;
	margin-bottom: 10px;
	color: #333333;
}
.header .small ul,
#posts_list .content .small ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.header .small ul li,
#posts_list .content .small ul li {
	float: left;
	font-size: 1em;
	color: #999999;
	margin-right: 15px;
}
.post_content {
	color: #222222;
	font-size: 14px;
	line-height: 24px;
	// margin-bottom: 40px;
	word-wrap: break-word;
	// overflow: hidden;
}
.post_content img ,
.post_content video {
    height: auto;
}
.post_content img,
.post_content video ,
.post_content iframe {
	display: block;
  max-width: 100%;
	margin: 0 auto;
}
.post_content {
	.pswp {
		img {
			max-width: none;
		}
	}
}
.bp-touchSwipe {
  overflow: auto;
  overflow-y: hidden;
  position: relative;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  width: 100%;
  height: 190px;
}
.bp-touchSwipe:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.bp-touchSwipe .bp-touchSwipe_inner,
.bp-touchSwipe .bp-inner_item {
  height: 180px;
}

.bp-touchSwipe .bp-touchSwipe_inner {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  -moz-transition: all, 0.1s, ease;
  -o-transition: all, 0.1s, ease;
  -webkit-transition: all, 0.1s, ease;
  transition: all, 0.1s, ease;
}

.bp-touchSwipe .bp-inner_item {
  width: 150px;
  /*height: 220px;*/
  float: left;
  margin-right: 10px;
}
.bp-touchSwipe .bp-inner_item a {
  color: #444444;
}
.bp-touchSwipe .bp-inner_item a:hover {
  text-decoration: none;
  color: #222222;
}
.bp-touchSwipe .bp-inner_item .title{
  font-size: 14px;
  margin-top: 5px;
  line-height: 22px;
}
.bp-touchSwipe_inner .item .img {
	height: 80px;
}
.no-padding {
	padding: 0;
}
.sections-content {
	padding: 10px 44px 30px;
}
#posts_list {
	position: relative;
}
#posts_list .list-item {
	// display: table;
	margin-bottom: 15px;
}
#posts_list .list-item .img-cover,
#posts_list .list-item .content {
	display: table-cell;
	vertical-align: top;
}
#posts_list .list-item .img-cover {
	padding-right: 10px;
	padding-top: 6px;
}
#posts_list.list .list-item .img-cover .img{
	width: 250px;
	height: 131px;
}

#posts_list .list-item a:hover,
.mean-category-posts .item a:hover {
	text-decoration: none;
}
#posts_list .content .title,
.mean-category-posts .item .title {
	font-size: 16px;
	color: #333333;
	font-weight: bold;
	line-height: 26px;
	padding-bottom: 0px;
	margin-bottom: 10px;
	border-bottom: 1px solid #eeeeee;
}
.mean-category-posts {
	margin-bottom: 30px;
}
.mean-category-posts .item .title {
	border: 0;
	margin-top: 10px;
}
#posts_list .content .title .small{
	font-weight: normal;
}
#posts_list .content .detail{
	color: #555555;
}


/*Grid view*/

#posts_list.grid .list-item {
	display: inline;
	margin-bottom: 5px;
	// height: 190px;
	overflow: hidden;
}
#posts_list.grid .list-item .img-cover,
#posts_list.grid .list-item .content {
	display: block;
	vertical-align: top;
}
#posts_list.grid .list-item .img-cover .img {
	// height: 115px;
}
#posts_list.grid .content .detail,
#posts_list.grid .content .small {
	display: none;
}
#posts_list.grid .content .title,
#posts_list.grid .mean-category-posts .item {
	border: 0;
	font-size: 14px;
	line-height: 24px;
}
#posts_list.grid .content .title {
	margin-top: 5px;
}
.right-small-post .img-cover{
	width: 50px;
	height: 50px;
	background-color: #eeeeee;
	background-size: auto 100%;
}

.single-right-content > .posts_item {
	margin-top: 30px;
	margin-bottom: 40px;
}
.single-right-content > .posts_item .title ,
.news-items > .title {
	font-size: 20px;
	position: relative;
	padding-bottom: 10px;
	font-family: 'Koulen', Arial, Helvetica, sans-serif;
}
.single-right-content > .posts_item .title > .border,
.news-items > .title > .border{
	position: absolute;
	width: 55px;
	height: 3px;
	background-color: #E4242C;
	left: 0;
	bottom: 0;
}
.news-items > .title {
	margin-top: 40px;
	margin-bottom: 5px;
}
.single-right-content .media-body a {
	font-weight: bold;
	color: #444444;
}
.news-items a > .title{
	margin-top: 5px;
}
.single-right-content .media-body a:hover {
	text-decoration: none;
}

.post_tags {
	// margin-top: 30px;
}
.post_tags > .title {
	font-size: 16px;
	font-weight: bold;
}
.post_tags a.tag {
	float: left;
	background-color: #F15B63;
	padding: 4px 10px;
	color: #ffffff;
	margin-top: 4px;
	margin-right: 4px;
}
.post_tags a.tag:hover {
	text-decoration: none;
}
#loading {
	text-align: center;
}
#loading i {
	display: none;
}
.facebook-comments {
	margin-top: 20px;
}

#footer {
	background-color: #000000;
	padding: 40px 0;
	margin-top: 80px;
	color: #ABAAAB;
}
img.footer-logo {
	height: 60px;
	margin-bottom: 10px;
}
#footer .copyright p {
	font-size: 14px;
	font-weight: bold;
}
#footer .copyright p strong {
	color: #dddddd;
}
#footer .copyright p.small{
	font-size: 14px;
	font-weight: normal;
}
#footer .copyright p.i{
	font-style: italic;
}
#footer .title {
	font-size: 19.9px;
	margin-bottom: 20px;
	font-family: 'Koulen', Arial, Helvetica, sans-serif;
}
#footer a {
	color: #ABAAAB;
}
#footer a:hover {
	color: #ffffff;
	text-decoration: none;
}
#footer .about ul {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: 20px;
}
#footer .about ul li{
	float: left;
	margin-right: 10px;
}
#footer .about ul li img{
	height: 40px;
}
#footer .about div.i{
	font-style: italic;
	font-weight: bold;
	margin-bottom: 30px;
	max-width: 300px;
}
#footer .list-socail {
	font-size: 24px;
	margin-right: 15px;
}
#footer .socail input{
	-webkit-border-radius: 0;
	border-radius: 0;
}
.horizon-swiper .horizon-item {
	width: 150px;
	margin-right: 10px;
}

.horizon-swiper .horizon-item .title {
	font-size: 16px;
	color: #777777;
	line-height: 24px;
	display: block;
}
.jumbotron {
	margin-top: 30px;
   padding: 25px !important;
   overflow: auto;
}
.jumbotron .title {
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 15px;
}
.jumbotron .title > span {
	color: #F85465;
}
.jumbotron .content {
	font-size: 14px !important;
}
.ads img{
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}
.fixed-ads{
	display: none;
	min-height: 36px;
}
.sidebar {
    position: absolute;
    width: 10px;
    height: 100%;
    top: 0;
}
.sidebar-left {
    left: -0px;
}

.content-outline {
	position: relative;
	width: 100%;
	padding: 0;
	margin: 0;
	display: block;
	padding: 10px 44px 20px;
	// overflow: hidden;
}

.content-footer {
	position: relative;
	width: 100%;
	padding: 0;
	margin: 0;
	display: block;
	padding: 20px 44px;
	overflow: hidden;
}

.sidebar-right {
    right: 0px;
}
.sidebar-bottom{
	position: absolute;
	width: 100%;
	bottom: 0px;
	height: 10px;
	left: 0px;
}
.single .sponsor_by{
	margin-top: 0px;
	margin-left: 0px;
	margin-right: 0;
}
.sponsor > a{
	padding: 0!important;
}
.sponsor, li.sponsor, .sponsor div{
	float: left;
}
.sponsor_by{
	margin-top: 15px;
	margin-left: 15px;
	margin-right: 0;
	font-weight: bold;
}
.single .sponsor_img{
	margin-top: -15px;
}
.sponsor_img{
	background-size: 65%;
	background-repeat: no-repeat;
	background-position: center;
	width: 150px;
	height: 44px;
	margin-left: -15px;
}
.angkor-pass-on-the-good li a{
	font-weight: bold;
	font-style: italic;
}
.post-top-bar {
    border-bottom: 1px solid #eee;
    height: 45px;
    padding: 0;
    padding-top: 10px;
    margin-bottom: 15px;
    position: relative;
}
.ads {
    /*margin-top: 10px;
    margin-bottom: 10px;*/
    text-align: center;
}
#ad_zone_07{
	margin-top: 0px;
    margin-bottom: 0px;
}

.space-wrapper{
	 float:left;margin-top: 5px;width:150px;height:20px;
}

.onoffswitch {
    position: relative; width: 40px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 20px; padding: 0; line-height: 20px;
    border: 1px solid #CCCCCC; border-radius: 20px;
    background-color: #FFFFFF;
    transition: background-color 0.3s ease-in;
}
.onoffswitch-label:before {
    content: "";
    display: block; width: 20px; margin: 0px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 19px;
    border: 1px solid #CCCCCC; border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: #ED1F37;
}
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
   border-color: #ED1F37;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px;
}
.onoffswitch-wrapper{
	position: absolute;
	right: 0px;
	top: 0px;
}
.onoff-header{
	position: absolute;
	/*right: 65px;*/
	top: 0px;
	/*color: #444444;*//*#EC1E24;*/
}
.article_padding{
	position: relative;
	// padding: 0px 20px;
	// padding-bottom: 20px;
	// margin-left: -15px;
	// margin-right: -15px;
}
.op-slideshow {
	border: 1px solid #c0392b;
	text-align: center;
	margin: 20px 0;
}
.op-slideshow figcaption {
  background-color: #c0392b;
  color: white;
}
.rrssb-buttons {
	margin-bottom: 10px;
}
.item p {
	margin: 0;
}
.btn_next_prev#btn_next,
.btn_next_prev#btn_prev {
	position: absolute;
	width: 35px;
	height: 100%;
	top: 0;
	cursor: pointer;
	color: #9E0910;
}
.btn_next_prev#btn_next:hover,
.btn_next_prev#btn_prev:hover {
	color: #D90C16;
}
.btn_next_prev#btn_next i,
.btn_next_prev#btn_prev i {
	position: absolute;
	top: 45%;
	font-size: 30px;
}
.btn_next_prev#btn_next i {
	left: 8px;
}
.btn_next_prev#btn_prev i {
	right: 8px;
}
.btn_next_prev#btn_next {
	right: -35px;
}
.btn_next_prev#btn_prev {
	left: -35px;
}
.bp-touchSwipe_inner #btn_prev {
	position: relative;
	float: left;
	left: 0;
	margin-right: 10px;
}
.relative {
	position: relative;
}
#related_posts {
	min-height: 100px;
}
#related_posts .web{
	padding: 0 0px;

	.row {
		.item {
			height: 175px;
			overflow: hidden;
		}
	}
}
.single-left-content .facebook-comments {
	margin-left: -10px;
	margin-right: -10px;
	overflow: hidden;
}
.related_post {
	padding: 0;
	position: relative;
}
.related_post #btn_next{
	right: -25px;
}
.related_post #btn_prev{
	left: -25px;
}
.thumbnail-cover {
	overflow: hidden;
	background: #cccccc;
}
#tab_hot_news .hot-news .item .img {
	height: 200px;
}
#tab_hot_news .hot-news-items .item .img {
	height: 86px;
}
.tab-by-category .item .img {
	height: 86px;
}
.tab-by-category .left_news.item .img  {
	height: 192px;
}
.tab-by-category .mean-category-posts .item .img {
	height: 188px;
}
.related_post .item .img {
	height: 115px;
}
.cat-ads{
	display: table;
	margin-bottom: 15px;
}
.gateway-banner{
	margin-bottom: 8px;
	max-height: 70px;
	overflow: hidden;
}
.small{
	color:#999999;
}
.mean-content {
	position: relative;
	margin-top: 15px;
}
.right-hot-news {
	// color: rgba(0,0,0,0.7);

	.media-body a {
		// color: rgba(0,0,0,0.7);
	}
	.bg {
		background: #f6f6f6;
		padding: 5px 10px;
	}
}
.fix-bg {
	position: absolute;
	left: 0;
	top: -40px;
	width: 100%;
	height: 800px;
	overflow: hidden;

	.img {
		position: absolute;
		top: 0;
		left: -20px;
		height: 100%;
		top: 0;
		width: calc(100% + 40px);
		background-repeat: no-repeat;
		background-position: top center;
		background-size: 100% auto;

		-webkit-filter: blur(10px);
	  -moz-filter: blur(10px);
	  -o-filter: blur(10px);
	  -ms-filter: blur(10px);
	  filter: blur(10px);
	}

	.inside{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50%;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6f6f6+0,f6f6f6+100&0.5+0,1+100 */
		background: -moz-linear-gradient(top, rgba(246,246,246,0) 0%, rgba(246,246,246,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(246,246,246,0) 0%,rgba(246,246,246,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(246,246,246,0) 0%,rgba(246,246,246,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80f6f6f6', endColorstr='#f6f6f6',GradientType=0 ); /* IE6-9 */
	}
}

.post_content .content-grp-img {
	position: relative;
}
.share-wrapper{
	float: right;
	padding-right: 0;
	margin-right: 0px;
	width: 154px;
}
.ico li:last-child{
	position: relative;
	padding: 0px;
	float: right!important;
	margin-right:0!important;
	width: auto;
}
.rrssb-buttons li{
	width: 42px!important;
}
.rrssb-buttons .rrssb-text{
	display: none;
}
.error-content {
	padding-top: 30px;
	min-height: 360px;
	text-align: center;

	margin-top: 10px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: auto 100%;
}
.error-content h2{
	font-size: 48px;
}
.error-content .text-red{
	color: #9E0910;
}
.top_category_ads {
	ins {
		a, iframe {
			display: block;
			margin: 0 auto;
		}
	}
}
/*Kanha style*/
#menu.navbar-inverse .navbar-nav > li.text-center{
	display:none;
}
#features_post .special-item-1{
	height:408px;
}

#features_post .special-item-2, #features_post .special-item-3{
	height:204px;
}
#banner_mobile .special.item .category, #features_post .special.item .category {
    font-size: 13px;
    padding: 0 8px 0 15px;
    position: absolute;
    top: 10px;
    left: 0px;
    color: #ffffff;
    height: 30px;
    line-height: 30px;
}
.special .date{
	float:left;
	padding: 3px 5px;
}
.special .date span{
	color: black;
}
#features_post .special .mean-decription .decription, #banner_mobile .special .mean-decription .decription {
    padding: 30px 20px 20px 15px;
}
.special.collapse{
	width: 100%;
	background-color: #000;
	padding-left: 0;
	position:relative;
}
.special.nav > li > a {
    position: relative;
	display: block;
	padding: 12px 15px;
	color: #F803AD;
	text-transform: uppercase;
	font-size: 16px;
}
.special.nav > li > a:hover {
	background: transparent;
	color: #D90C16;
}
.special.nav{
	height:46px;
}
.special.nav > li:first-child > a{
	font-size: 20px;
}
.special #top_right_ads .ads_items  {
	height:auto;
	margin-bottom:10px;
}
.special #top_right_ads .ads_items:first-child{
	margin-top:10px;
}
.mean-content.special{
	margin-top : 0;
}
/* style for image carousel with flexisel.js*/
#flexiselDemo3 {
	display:none;
}

.nbs-flexisel-container {
    position:relative;
    max-width:100%;
}
.nbs-flexisel-ul {
    position:relative;
    width:99999px;
    margin:0px;
    padding:0px;
    list-style-type:none;
    text-align:center;
}

.nbs-flexisel-inner {
    position: relative;
    overflow: hidden;
    float:left;
    width:100%;
    background:#fcfcfc;
    background: #fcfcfc -moz-linear-gradient(top, #fcfcfc 0%, #eee 100%); /* FF3.6+ */
    background: #fcfcfc -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fcfcfc), color-stop(100%,#eee)); /* Chrome,Safari4+ */
    background: #fcfcfc -webkit-linear-gradient(top, #fcfcfc 0%, #eee 100%); /* Chrome10+,Safari5.1+ */
    background: #fcfcfc -o-linear-gradient(top, #fcfcfc 0%, #eee 100%); /* Opera11.10+ */
    background: #fcfcfc -ms-linear-gradient(top, #fcfcfc 0%, #eee 100%); /* IE10+ */
    background: #fcfcfc linear-gradient(top, #fcfcfc 0%, #eee 100%); /* W3C */
}

.nbs-flexisel-item {
    float:left;
    padding:0px;
    cursor:pointer;
    position:relative;
    line-height:0px;
}
.nbs-flexisel-item img {
    max-width: 100%;
    cursor: pointer;
    position: relative;
    height:145px;
}

/*** Navigation ***/

.nbs-flexisel-nav-left,
.nbs-flexisel-nav-right {
    position: absolute;
    cursor: pointer;
    z-index: 4;
    top:40%;
    width: 40px;
	height: 40px;
}

.nbs-flexisel-nav-left {
    left: 10px;
}

.nbs-flexisel-nav-left{
   background-image: url('../../img/arrow-left.png');
   background-size:100%;
   background-position:center;
   background-repeat:no-repeat;
}

.nbs-flexisel-nav-left.disabled {
    opacity: 0.4;
}

.nbs-flexisel-nav-right {
    right: 5px;
}

.nbs-flexisel-nav-right{
   background-image: url('../../img/arrow-right.png');
   background-size:100%;
   background-position:center;
   background-repeat:no-repeat;
}

.nbs-flexisel-nav-right.disabled {
    opacity: 0.4;
}
.video-slide-wrapper{
	padding-left: 0px;
	padding-right: 0px;
}

.f-wrapper{
	height: 276px;
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	transition: all .5s ease-in-out;
	margin-bottom:15px;
}
.f-wrapper:hover{
	background-size: 110%;

}
.cap{
	position: absolute;
	z-index: 2;
	font-size: 18px;
	text-shadow: none;
	color: #fff;
	background: url(../../img/caption.png);
	bottom: 0;
	display: block;
	width: 100%;
	padding: 10px 5px;
	left: 0;
}
p.cap{
	margin-bottom: 0px;
}
.most-popular .cap{
	font-size: 16px;
}
.cat-title-news a,.cat-title-lifestyle a, .cat-title-road-home a{
	color: #F6951C;
}
.home-tabs.news{
	background-color: #F6951C;
}
.cat-title-beauty-fashion a, .cat-title-beauty a{
	color: #AFC637;
}
.cat-title-health-sex a, .cat-title-health a{
	color: #3B3596;
}
.cat-title-love-wedding-family a, .cat-title-fashion a{
	color: #4067B2;
}
.cat-bar-news, .cat-bar-lifestyle, .cat-bar-road-home{
	background-color:#F6951C;
	width: 100%;
	height:10px;
	margin: 0 0 10px 0;
}
.cat-bar-beauty-fashion, .cat-bar-beauty{
	background-color: #AFC637;
	width: 100%;
	height:10px;
	margin: 0 0 10px 0;
}
.cat-bar-health-sex, .cat-bar-health{
	background-color: #3B3596;
	width: 100%;
	height:10px;
	margin: 0 0 10px 0;
}
.cat-bar-love-wedding-family ,.cat-bar-fashion{
	background-color: #4067B2;
	width: 100%;
	height:10px;
	margin: 0 0 10px 0;
}
.cat-bar{
	margin-top: 10px;
	height: 10px;
	float: left;
	width: 74%;
}
.sm-thum{
	height: 60px;
	padding-right: 10px;
	background-position: center center;
	background-size: 100% auto;
}
.special-tag-wrapper{
	margin-top:10px;
}
.cat-list-title {
    font-size: 24px;
    color: #4b4b4b;
    padding-left: 0px;
    font-family:'Koulen', Arial, Helvetica, sans-serif;
    margin-top:0px;
    width:26%;
}
.cat-title-wrapper{
	padding:10px 0;
}
.cat-list-title a{
	text-decoration:none;
	text-transform:uppercase;
}

.break-text {
    display: block;
    line-height: 19px;
    position: relative;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    max-height: 4em;
    overflow: hidden;
    color: #000;
}
.col-article{
	margin-bottom:15px;
}
.padding-left{
	padding-left:15px;
}
#menu.navbar{
	background-color:#1D4B8F;
}
.no-padding-right{
	padding-right: 0px;
}
.video, .video:hover{
	text-transform:uppercase;
	color:#000;
	text-decoration:none;
}
.video h3{
	position: relative;
	width: 100%;
	margin-top: 0;
	background: #ddd9cd;
	margin-bottom: 0;
	padding: 6px 12px;
	color: #000;
	font-size: 22px;
}
.menu_kanha img{
	width:30px;
}
.mean-content.special #features_post{
	height:auto;
}
.navbar-ex1-collapse div.category{
	display:none;
}
.menu_sabay_kanha img{
	width:30px;
	margin-top: -5px;
}
.special .single-right-content{
	margin-top:10px;
}
.special.nav .active a{
	//background-color: #FA1939;
	color: #FA1939;
}
.nav.navbar-nav li a.mobile{
	display:none;
}

.nav-tabs > li.sponsor {
	width: auto;
}
/*End of kanha style*/
.home-tabs li.title a p{
    float: left;
    margin-right: 5px;
    margin-bottom: 0;
    padding-left: 0px;
}

.audiojs .play-pause{
	padding: 4px 1px;
}

#footer .container-fluid {
	padding-left: 60px;
}
#footer .download img {
	width: 120px;
	float: left;
}
@media (max-width: 1199px) {
	#tab_hot_news .hot-news .item .img {
		height: 170px;
	}
	#tab_hot_news .hot-news-items .item .img {
		height: 60px;
	}
	.tab-by-category .item .img {
		height: 60px;
	}
	.related_post .item .img {
		height: 80px;
	}
	.tab-by-category .mean-category-posts .item .img {
		height: 136px;
	}
	#posts_list.list .list-item .img-cover .img {
		height: 84px;
    	width: 160px;
	}
	#posts_list.grid .list-item .img-cover .img {
		// height: 80px;
	}
}
@media (max-width: 991px) {
	#tab_hot_news .hot-news .item .img {
		height: 130px;
	}
	#tab_hot_news .hot-news-items .item .img {
		height: 41px;
	}
	.hot-news-items .item {
		padding: 0 5px;
	}
	.related_post .item .img {
		height: 70px;
	}
	.tab-by-category .mean-category-posts .item .img {
		height: 73px;
	}
	#posts_list.grid .list-item .img-cover .img {
		// height: 73px;
	}
	.hot-news-items {
		width: 100%;
	}
	.hot-news-items .row {
		margin: 0;
	}
}


@media (max-width: 767px) {
	div.detail.content-detail span div.embed-responsive.embed-responsive-4by3 {
		padding-bottom: 134% !important;
	}

	.app.nav .sponsor_by{
		color:#fff;
	}
	.app .sponsor{
		position:absolute;
		right:0;
	}
	.app.home-tabs > li.title > a {
		padding:10px;
	}

	.home-tabs>li.title>a {
		padding-left:15px;
		padding-right:0px;
	}
	.special.collapse{
		display:none;
	}
	.f-wrapper{
		height:175px;
	}

	.nav.navbar-nav li a.mobile{
		display:block;
	}
	.nav.navbar-nav li a.web{
		display:none;
	}
	#menu.navbar{
		background-color:#000;
	}
	.single-left-content{
		// padding-left: 15px;
		// padding-right: 15px;
		// padding-top: 0px;
	}
	.single-left-content .header {
		padding-bottom: 0;
	}
	.content-outline, .content-footer {
		padding: 15px;
	}

	.content-outline {
		padding-top: 0;
	}

	.content-outline.has-sponsor {
		padding: 15px 20px;
		padding-top: 0;
	}

	.single-left-content #ad_zone_05 img ,
	.single-left-content #ad_zone_05 iframe {
		margin: 15px auto;
	}
	.sponsor{width:214px;}
	.sponsor_img{
		margin-left: -15px;
	    width: 125px;
	    height: 45px;
	}
	.fixed-ads{
		display: block;
	}
	.tab-by-category .item .img {
		height: 80px;
	}
	#ads-close{
	    margin: 0px auto;
	    padding: 5px 5px;
	    position: absolute;
	    right: 0px;
	    width: 20px;
	    background: #710101 none repeat scroll 0% 0%;
	    color: #FFF;
	    height: 20px;
	    top: 0px;
	}
    #top {
		display: none;
	}

	#features_post {
		width:100%;
		height: auto;
	}
	#banner {
		display: none;
	}
	#banner_mobile {
		display: inherit;
	}
	.web {
		display: none;
	}
	.mobile {
		display: inherit;
	}
	.home-tab-content {
	    padding: 20px;
	    min-height: 100px;

	    .tab-content-banner {
				margin: -20px -20px 15px -20px;
			}
	}
	.container {
		padding: 0;
	}

	.single-left-content.no-padding {
		padding: 0;
	}
	.row {
		margin: 0;
	}
	.tab-item {
	    margin-top: 0;
	}
	.tab-by-category .tab-pane {
	    min-height: auto;
	}
	#footer {
		margin-top: 50px;
	}
	#footer .about ,
	#footer .new-news {
		display: none;
	}
	#footer .copyright,
	#footer .socail, #footer .download {
		text-align: center;
	}
	#footer .download {
		font-size: 20px;
	}

	#footer .download img {
		width: 150px;
		float: left;
	}

	#footer .container-fluid {
		padding-left: 15px;
	}

	.single-left-content {
		width:100%;
	}
	.single-right-content {
		width: 300px;
		margin: 0 auto;
		float: none;
		padding-top: 20px;
		clear: both;
	}
	.right-pop-news {
		display: none;
	}
	.list-items .list-item {
		padding: 0 5px;
	}
	li.switch-view-li {
		display: none;
	}
	.navbar-brand {
	    display: inherit;
	    float: none;
	    margin: 0 auto;
	    width: 64px;
	}
	.navbar-toggle {
	    float: left;
	    margin-left: 5px;
	}
	#posts_list .list-item .img {
		padding-right: 0px;
	}
	#posts_list .mean-category-posts .item {
	    padding: 0 5px;
	}
	.home-tab-content {
	    padding: 20px 15px 5px 15px;
	}
	#posts_list .item {
		// height: 157px !important;
		overflow: hidden;
		margin-top: 0px;
	}
	#posts_list.grid .content .title,
	#posts_list.grid .mean-category-posts .item {
		line-height: 22px;
	}
	#posts_list .item .title,
	.mean-category-posts .item .title {
		font-size: 14px;
		font-weight: normal;
		line-height: 22px;
		border-bottom: 0px solid #eeeeee;
		margin-top: 5px;
	}
	.tab-content #btn_next i,
	.tab-content #btn_prev i {
		top: 30%;
	}
	#posts_list.grid .list-item .img-cover .img ,
	.tab-by-category .mean-category-posts .item .img {
		// height: 76px;
	}
	.post_content {
		overflow: inherit;
	}
	.post_content .content-grp-img ,
	.post_content .content_slider {
		margin-left: -15px;
		margin-right: -15px;
		z-index: 100;
	}
	.post_content.article_padding .content-grp-img ,
	.post_content.article_padding .content_slider {
		margin-left: -20px;
		margin-right: -20px;
		z-index: 100;
	}
	.post_content .content-grp-img .caption{
		padding: 0 15px;
		background-color: #fff;
	}
	.post_content.article_padding .content-grp-img .caption{
		padding: 0 20px;
	}
	.content_slider{
	    height: 290px;
	}


	.content_slider .item img,
	#banner_mobile .item > a img {
		height: 220px;
		max-width: none;
		width: auto;
		margin: 0 auto;
	}
	#banner_mobile .carousel-inner {
		background: #000000;
	}
	.carousel-inner .item .img {
		height: 250px;
	}
	.onoffswitch-wrapper{
		right: 0px;
	}
	.onoff-header{
		left: 0px;
	}
	.rrssb-buttons li a .rrssb-text {
	    display: none;
	}
	.no-lr-padding{
		padding-left: 0px;
		padding-right: 0px;
	}
	.share-wrapper{
		margin-top: 0px;
		margin-bottom: 0px;
		width: 141px;
	}

	ul.ico li:last-child{
		position: relative;
		width: 100%;
		height: 20px;
	}
	.content_slider .carousel-control {
		width: 80px;
		height: 80px;
		bottom: calc(50% - 30px);
	}
	.content_slider .carousel-control.left {
		left: -40px;
		padding-right: 15px;
	}
	.content_slider .carousel-control.right {
		right: -40px;
		padding-left: 15px;
		text-align: left;
	}
	.content_slider .carousel-control .fa {
		font-size: 55px;
		line-height: 80px;
		text-align: right;
	}
	#posts_list .list-item .img-cover {
		padding-right: 0;
		padding-top: 0px;
	}
	.special-tag-wrapper {
	    margin-top: 0px;
	}
	.no-padding, .no-padding-right{
		padding: 0px;
	}
	.cat-list-title{
		width:48%;
		float:left;
		font-size:22px;
	}
	.cat-bar {
		margin-top:10px;
	    width:51%;
	    float:left;
	}
	.col-article, .cat-list-title a{
		padding:0 5px;
	}
	.f-wrapper{
		width: 100%;
	}
	.cat-title-wrapper .cat-list-title{
		margin-top:0;
	}
	#menu.navbar-inverse .navbar-nav > li:first-child > a{
		width: 100%;
	}
	#menu.navbar-inverse .navbar-nav > li > a{
		width:50%;
		float:left;
	}
	#menu.navbar-inverse .navbar-nav > li.text-center{
		display:block;
	}
	.sabay-menu-logo{
		height:20px;
		display:initial;
	}
	.navbar-ex1-collapse div.category{
		display:block;
		padding:10px 0;
		text-align:center;
	}
	.home-tabs li.title{
		max-width:94%;
		position:relative;
	}
	.home-tabs li.title a p{
	    padding-left: 3px;
	    margin-left: 0px;
	    margin-right: 0px;
	    max-width: 95%;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	}

	.vdo-ads-wrapper {
		margin-left: -25px;
	}

}

@media (max-width:768px) {
  .no-ipad {
    display: none;
  }

  .ipad {
  	display: inherit;
  }

  .single-left-content {
		width:100%;
	}
	.single-right-content {
		width: 300px;
		margin: 0 auto;
		float: none;
		padding-top: 20px;
		clear: both;
	}

	.audiojs .play-pause{
		padding: 4px 1px;
	}

	.audiojs{
		width: 100%;
		color: #fff;
	}
	.error .error-message {
		position: absolute;
		left: 40px;
		font-size:10px;
	}
	.audiojs .error-message a{
		color: #eee;
		text-decoration: none;
		padding-bottom: 1px;
		border-bottom: 1px solid #999;
	}

	.audiojs .scrubber{
		position: absolute;
	    width: 50%;
	    left: 34px;
	}

	.audiojs .time{
	    position: absolute;
    	right: 15px
	}

}

@media (max-width:420px) {
	.post_content img, .post_content video {
	    height: auto;
	}
}

#banner_mobile .special.item .category, #features_post .special.item .category {
    font-size: 16px;
	padding: 3px 12px;
	position: relative;
	color: #181818;
	background-color: #f6951c;
	float:left;
	font-family: 'Koulen', Arial, Helvetica, sans-serif;
}
.special .date{
	float:left;
	padding: 3px 5px;
}
.special .date span {
    color: #fff;
}
#features_post .special .mean-decription .decription, #banner_mobile .special .mean-decription .decription {
    padding: 30px 20px 20px 15px;
}

.block-above-ad {
  box-shadow: 0 9px 12px -4px rgba(50, 50, 50, 0.75);
  margin-left: -20px;
  margin-right: -20px;
  padding-bottom: 20px;
}

.block-bottom-ad {
  padding-top: 15px !important;
  box-shadow: 0 -4px 4px rgba(50, 50, 50, 0.75);
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px 0;
}
@media (max-width: 767px) {
  .post_content .content-grp-img, .post_content .content_slider {
	margin-left: -15px;
	margin-right: -15px;
	z-index: 100;
	padding: 5px 0;
	margin: 0 -20px;
	background-color: #fff;
  }

  .post_content ol, .post_content ul {
	background-color: #fff;
    margin: -5px -20px 0;
  }

  .ad-cover {
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .ad-cover, .ad-cover:hover {
    z-index: -1;
  }

  .content-outline {
	padding: 0 20px;
  }

  .content-outline .category_bottom_ads {
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  .article-page .category_bottom_ads, .content-outline .category_bottom_ads {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    overflow: hidden;
    left: 0;
  }

  .article p {
	background-color: #fff;
    position: relative;
    margin: -5px -20px;
    padding: 5px 20px;
  }

  .category_bottom_ads {
    background-image: url("../../img/logo.png");
    background-position: center;
    background-size: 100px;
    background-repeat: no-repeat;
  }

  .content-outline .header.post_content, .content-outline .row {
    background: #fff;
    z-index: 1;
  }

  .content-outline .header.post_content, .content-outline .row {
    position: relative;
    z-index: 1;
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px;
    background-color:#fff;
    margin-top: 0;
  }

  .push-ad .content-grp-img {
    background: #fff;
    margin: 0px;
    padding: 10px 0;
  }

  .push-ad p {
    background: #fff;
    margin-left:-20px;
    margin-right: -20px;
    padding: 20px;
    margin-bottom: 0;
  }

  .block-above-ad {
    margin-left:-20px!important;
    margin-right: -20px!important;
    padding: 20px!important;
  }

  .header.post_content{
    margin-bottom: 0;
  }

  .article #ad_zone_02, .article #ad_zone_03 {
    background-color: #ccc;
    position: relative;
    height: 250px;
    z-index: 1;
    margin-top: 10!important;
  }

  .article .content-footer {
    background-color: #fff;
    position: relative;
    width: 100%;
    z-index: 1;
    padding: 20px;
    margin-top: 0!important;
    margin-bottom: 0!important;
  }

  .article .single-right-content {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding: 20px;
    margin-top: 0!important;
    margin-bottom: 0!important;
    width: 100%;
  }

  #footer {
    background-color: #000000;
    padding: 40px 0;
    color: #ABAAAB;
    z-index: 1;
    position: relative;
    margin-top: 0;
  }

  .author {
    background-color: #fff;
    margin: -5px -20px;
    padding: 5px 20px;
    position: relative;
    z-index: 1;
  }

  .content-footer, .content-outline {
    position: relative;
    margin: 0;
    display: block;
  }

  .block-bottom-ad p{
    padding: 0 15px;
	}

	.article #ad_zone_02, .article #ad_zone_03 {
		background-color: #fff;
	}

	.content-outline.underlay {
		padding: 0 20px;
	}

}

#ad_zone_05 img {
  max-width: 100%;
  height: auto;
}

.fancybox-overlay-fixed {
  z-index: 100000;
}

.fancybox-opened {
  z-index: 100001;
}

.block-bottom-ad {
  position: relative;
  background-color: white;
  padding: 5px;
}

.ad-wrapper {
  z-index:1;
  background-color: #fff;
  width: 100%;
  padding: 5px;
  position: relative;
}

.advertise-title {
	text-align: center;
  color: #9a9a9a;
}

.mb-minus-15px {
	margin-bottom: -15px;
}

/* Donation */
.donation-single-left-content {
	background: #FFF4EB;
	padding-bottom: 50px;
}
.donation-single-left-content .container_header {
	height: 220px;
	padding: 20px;
}
.donation-single-left-content .donation_title {
	text-align: left;
	font-family: 'Koulen', Arial, Helvetica, sans-serif;
	line-height: 1.4;
	font-size: 30px;
	font-weight: normal;
	letter-spacing: 1px;
	color: #FFFFFF;
	margin-top: 20px;
  white-space: pre;
}
.donation-single-left-content .donation_sub_title {
	text-align: left;
	white-space: pre;
	margin-top: 25px;
	color: #FFFFFF;
	line-height: 1.5;
}

.donation-single-left-content .container_body {
	padding: 20px;
	display: flex;
}

.donation-single-left-content .donation_title_message {
	text-align: left;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bold;
	font-size: 18px;
	color: #383838;
	margin-top: 0px;
}

.donation-single-left-content .donation_sub_title_message {
	text-align: left;
	letter-spacing: 0px;
	color: #383838;
	margin-bottom: 10px;
}

.donation-single-left-content .donation_message {
	text-align: left;
	font-size: 14px;
	color: #383838;
	margin-bottom: 5px;
	font-family: Battambang,Arial,Helvetica,sans-serif;;
	line-height: 1.4;
	margin-left: 16px;
}
.donation-single-left-content .number-list {
	margin-left: -16px;
}

.donation-single-left-content .donation_detail_left {
	width: 65%;
}
.donation-single-left-content .donation_qr_code {
	width: 35%;
}

.donation-single-left-content .back_btn {
	width: 100%;
	margin: 30px 0px 30px 0px;
	align-self: center;
	display: flex;
	justify-content: center;
}

.donation-single-left-content .donation_qr_code {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
}

.donation-single-left-content .qr_code_img {
	width: 180px;
	height: 240px;
}

.donation-single-left-content .donation_btn_back {
	border-radius: 8px;
	border: 1px solid gray;
	font-family: 'Koulen', Arial, Helvetica, sans-serif;
	background-color: #FFF4EB;
}

.donation-single-left-content .donation_btn_back a {
	color: #393939;
	text-decoration: none;
	margin-top: 0px;
}

.donation-single-left-content .donation_btn_back a span {
	margin-left: 5px;
}

#donation-modal {
  z-index: 99999;
}

#donation-modal .modal-content {
  border-radius: 0px;
	opacity: 1;
}

#donation-modal .modal-dialog {
  margin: 200px auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

#donation-modal .donation-close {
  padding: 0px 5px;
  border: 1px solid gray;
  border-radius: 50%;
	background: #fff;
	margin: 10px;
  opacity: unset;
  position: absolute;
  right: 0;
}

#donation-modal .modal-body {
  padding: 0;
}

.btn-join-now {
  height: 44px;
  background: transparent linear-gradient(255deg, #FF8400 0%, #EC0186 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  color:#fff;
  font-family: 'Koulen', Arial, Helvetica, sans-serif;
  letter-spacing: 0px;
  position: absolute;
  left: 50%;
  top: 80%;
  margin-left: -65px;
  padding-top: 9px;
  padding-left: 30px;
  padding-right: 30px;
}

.btn-join-now:hover {
	cursor: pointer;
	color: #fff;
	background: transparent linear-gradient(250deg, #a75b0a 0%, #98135e 100%) 0% 0% no-repeat padding-box;
}

.d-sm-none {
  display: block;
}

.d-md-none {
  display: none;
}
@media screen and (max-width: 992px) {
	.donation-single-left-content .donation_title {
		font-size: 24px;
	}
}
@media screen and (max-width: 767px) {
	.donation-single-left-content .donation_title {
		font-size: 22px;
	}

	.donation-single-left-content .donation_detail_left,
	.donation-single-left-content .donation_qr_code {
		width: 100%;
	}

	.donation-single-left-content .container_body  {
		display: flex;
		justify-content: center;
		text-align: center;
		flex-direction: column;
	}

	.donation-single-left-content .donation_qr_code {
		align-self: center;
	}
  
  #donation-modal .modal-dialog {
    margin: 50px;
  }
  .d-sm-none {
    display: none;
  }

  .d-md-none {
    display: block;
  }
}
/* /Donation */
