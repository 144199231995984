
#menu {
	background: #9E0910;
	color: #BDB9A6;
	border: 0;
	margin-bottom: 15px;
	border: 0;
	margin: 0;
}
nav#menu .nav {
	width: 100%;
}
#menu #search {
	position: absolute;
	top: 0;
	right: 0;
	padding-right: 15px;
	height: 50px;
	background: transparent;
	/*padding-left: 15px;*/
	height: 100%;
}
#menu #search form {
	position: relative;
	height: 100%;
}
#menu #search form button {
	position: absolute;
	top: 0px;
	right: 0px;
	color: rgba(255, 255, 255, 0.8);
	width: 40px;
	height: 100%;
	border: 0;
	background: transparent;
	text-align: center;
	/*line-height: 28px;*/
	padding: 0;
	font-size: 18px;
}
#menu #search form button.left_submit {
	left: 0;
	display: none;
}
#menu #search form a {
	float: right;
	color: #ffffff;
	font-size: 16px;
	margin-left: 10px;
	padding-top: 12px;
	height: 100%;
}
#menu #search input{
	width: 0;
	height: 100%;
	padding: 0;
	line-height: 30px;
	background: #6F060B;
	color: #ffffff;
	border: 0;
}
#menu #search input:focus{
	background: #6F060B;
}
.font-strong {
	font-family: 'Koulen', Arial, Helvetica, sans-serif;
}
#menu.navbar-inverse .navbar-nav > li > a {
  color: rgba(255,255,255,0.8);
  font-family: 'Koulen', Arial, Helvetica, sans-serif;
  font-size: 18px;
  position: relative;
}
#menu.navbar-inverse .navbar-nav > li > a > img {
  height: 50px;
  margin-top: -21px;
  margin-bottom: -20px;
}
#menu.navbar-inverse .navbar-nav > li > a:hover,
#menu.navbar-inverse .navbar-nav > li.active > a {
  color: #ffffff;
  @include transition(.3s);
}
#menu.navbar-inverse .navbar-nav > li.active > a {
  background: #006CCD;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}

nav#menu .nav > li.more {
	display: none;
}

@media (max-width: 767px) {
	#menu {
		margin: 0;
		background: #161616;
	}
	nav#menu .nav {
		width: auto;
	}
	#menu .container {
		padding: 0 15px;
	}
	#menu #search {
		background: #161616;
		height: 50px;
	}
	#menu #search input{
		width: 0px;
		padding: 0;
	}
	#menu #search form button {
		color: #ffffff;
		cursor: pointer;
	}
	.mean-content {
		margin-top: 0px;
	}
	.navbar-nav {
		margin: 0;
		margin-left: -15px;
		margin-right: -15px;
		padding: 0;
	}
	
	#menu .nav.navbar-nav li a img{
		width:auto;
		height:40px;
	}
}