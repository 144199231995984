.box{
  position: relative;
  width: 100%;   /* desired width */

  .ele{
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #161616;
    background-position: center center;
    background-repeat: no-repeat;

    // transition: all 0.1s ease;

    background-size: calc(100% + 10px) auto;
  }

}
.box:before{
  content: "";
  display: block;
  padding-top: 100%;  /* initial ratio of 1:1*/
}

.item:hover .box .ele {
  background-size: 100% auto;

  transition: all 0.2s ease;
  -webkit-box-shadow:inset 0 0 10px 0 rgba(0,0,0,9);
  box-shadow:inset 0 0 10px 0 rgba(0,0,0,9);
}
/* Other ratios */
.ratio2_1:before{
  padding-top: 50%;
}
.ratio1_2:before{
  padding-top: 200%;
}
.ratio4_3:before{
  padding-top: 75%;
}
.ratio16_9:before{
  padding-top: 56.25%;
}

.list-items {

  .list-item {
      padding: 0;

      .tumbnail {
        padding-right: 0;
      }
  }

  .list-item:hover {
    text-decoration: none;
  }

}

.grid {
  .list-items {
    padding: 0 5px;

    .list-item {
      position: relative;
      overflow: hidden;

      a {
        position: absolute;
        width: calc(100% - 20px);
        top: 0;
        left: 10px;
        bottom: 0;
        right: 0;
        overflow: hidden;
      }
    }

    .list-item:before{
      content: "";
      display: block;
      padding-top: calc(80% + 10px); /* initial ratio of 1:1*/
    }

  }
}

.category-tab-content {
  position: relative;
  padding: 0;
}

#posts_list {
  padding: 25px;
}

.grid {
  .list-items {    
    .list-item {
      min-height: 180px;      
      .tumbnail, .content{
        padding-left: 0px;
      }
    }
  }
}    

@media (max-width: 991px) {
  .list {
    .list-item {
      .tumbnail {
        padding-right: 15px !important;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 767px) {

  .grid {
    .list-items {

      padding: 0 15px;

      .list-item {
        a {
          width: calc(100% - 10px);
          left: 5px;
        }
      }

      .list-item:before{
        padding-top: calc(80% + 30px); /* initial ratio of 1:1*/
      }
    }
  }

  .mean-category-posts {
    padding: 0 15px;
  }

  #posts_list {
    padding: 0px;
    padding-top: 20px;
  }
  
}